import { useEffect } from 'react';
import { Subject } from 'rxjs';

function useUnmountSubject() {
    const unmountSubject = new Subject<void>();
    useEffect(() => {
        return () => {
            unmountSubject.next();
            unmountSubject.complete();
        };
    }, []);

    return unmountSubject;
}

export default useUnmountSubject;
